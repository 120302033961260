.wrapper {
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-image: url("../../../assets/img/officeBackground.png");

  .logo {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    img {
      padding: 1rem 2rem;
      height: 10rem;
      width: 10rem;
    }
  }

  .heading {
    display: flex;
    flex-wrap: nowrap;

    .logoWrapper,
    .headingWrapper {
      width: 50%;
      display: inline-block;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .headingWrapper {
      flex-wrap: wrap;

      p {
        color: #fff;
        font-size: 35pt;
        font-family: "Proxima Nova S";
        letter-spacing: 0.125rem;
        line-height: 2.5rem;

        .buttons {
          margin-top: 2rem;
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          button {
            color: #fff;
            font-family: "Proxima Nova";
            font-size: 15pt;
            border-radius: 0.25rem;
            padding: 0.25rem;
            font-weight: 500;
            background-color: transparent;
            border: solid #fff 0.125rem;
            transition: 0.4s;
            cursor: pointer;
            margin-right: 2rem;

            &:hover {
              background-color: #fff;
              color: rgb(78, 78, 78);
            }
          }

          a {
            line-height: 2rem;
            opacity: 0.75;
            cursor: pointer;
            transition: 0.4s;
            margin-right: 1rem;

            &:hover {
              opacity: 1;
            }

            img {
              height: 2rem;
            }
          }
        }
      }
    }
  }
}

.footer {
  padding: 0.5rem 0;
  background-color: #344149;
  display: flex;
  justify-content: space-around;

  a {
    color: #fff;
    font-family: "Proxima Nova S";
    font-size: 13pt;
  }
}

@media screen and (max-width: 1024px) {
  .heading {
    div {
      width: 100% !important;
    }
    .logoWrapper {
      display: none !important;
    }
  }
}
