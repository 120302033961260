body {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./assets/fonts/proxima\ nova/Proxima Nova.otf") format("opentype");
}

@font-face {
  font-family: "Proxima Nova A";
  src: url("./assets/fonts/proxima\ nova/Proxima Nova A.otf") format("opentype");
}

@font-face {
  font-family: "Proxima Nova S";
  src: url("./assets/fonts/proxima\ nova/Proxima Nova S.otf") format("opentype");
}
