.wrapper {
  background-image: url("../../../assets/img/lightBackground.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh;
  padding: 2rem 4rem;
  box-sizing: border-box;
  user-select: none;

  h1 {
    margin-top: 0;
    font-family: "Proxima Nova S";
    font-size: 28pt;
  }
  p {
    font-family: "Proxima Nova";
    font-size: 14pt;

    .smaller {
      font-size: 11pt;
    }
  }
}

@media screen and (max-width: 1024px) {
  .wrapper {
    padding: 1rem 2rem !important;
  }
}